:root {
  --loader-main: #1a671e;
  --loader-symbols: #fff;
}
$laptop: 1200px;
$tablet: 900px;
$mobile: 600px;
$background-color: #f9f9f9;
$background-card-color: #fff;
$color1: #0e1d31;
$color2: #6e7783;
$color3: #3e4a5a;
$color4: #e6e8ea;
$error: #8b3116;
$accent-color: #7daf3e;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: $background-color;
  height: 100vh;
  color: $color1;
  display: flex;
  flex-direction: column;
}

.header,
.card {
  background-color: $background-card-color;
}

.header {
  flex: none;
  padding: 20px 80px;
  img {
    height: 48px;
  }
  @media screen and (max-width: $tablet) {
    padding: 16px;
    img {
      height: 32px;
    }
  }
}

.card {
  color: $color3;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.08);
}

.main {
  flex: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .content {
    padding: 50px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    @media screen and (max-width: $tablet) {
      padding: 30px 0;
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }
}

.content,
.footer {
  max-width: min(90%, 1024px);
}
.footer {
  margin-bottom: 50px;
  color: $color2;
  font-size: 10px;
  .c {
    text-align: center;
  }
}

.splash {
  .title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }
  img {
    width: 100%;
    &.tt {
      margin-top: 32px;
    }
  }
}

.info {
  .table {
    padding: 30px 0;
    .line {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > span:last-child {
        font-weight: bold;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $color4;
      }
    }
  }
  .action {
    display: flex;
    justify-content: center;
  }
}

.done {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    margin-bottom: 32px;
  }
}

.button {
  background-color: $accent-color;
  color: $background-card-color;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 32px;
  cursor: pointer;
  &:hover {
    background-color: #1a671e;
  }
}

.error {
  margin-top: 32px;
  font-size: 12px;
  font-weight: bold;
  color: $error;
}
